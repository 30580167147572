import key from 'weak-key';

// types
import type { ImageData } from './image.types';

// components
import { SingleHoverImage } from './single-hover-image';

// utils
import { isEmpty } from 'utils/is-empty';

type HoverImageProps = {
  images: ImageData[];
  className?: string;
};

function HoverImage({ images, className }: Readonly<HoverImageProps>) {
  if (isEmpty(images)) return null;

  return (
    <div className={className}>
      {images.map((image) => {
        return <SingleHoverImage image={image} key={key(image)} />;
      })}
    </div>
  );
}

export default HoverImage;
