import { TitleFormats } from '../Title/title.types';

export function getTitleFormat(isGdds: boolean, isChild: boolean, isTeaserColumn = false) {
  let format: keyof typeof TitleFormats = isTeaserColumn ? TitleFormats.h3 : TitleFormats.h2;
  if (isGdds) {
    format = TitleFormats.h3;
  } else if (isChild) {
    format = TitleFormats.h4;
  }
  return format;
}
